import request from '@/utils/request'
import qs from 'qs';

// 获取直播班级列表
export function getLiveList() {
  return request({
    url: '/live.Live/list',
    method: 'post',
  })
}

// 获取直播班级详情
export function getLiveDetail(data) {
  return request({
    url: '/live.Live/single',
    method: 'post',
    data: qs.stringify(data),
  })
}

// 保存播放时间记录
export function savePlayRecord(data) {
  return request({
    url: '/live.Live/single',
    method: 'post',
    data: qs.stringify(data),
  })
}

// 获取直播班级列表
export function getLiveGradeList(){
	return request({
	  url: 'live/grade',
	  method: 'post',
	})
}

// 专业下节直播列表
export function getGradeLiveList(data){
	return request({
	  url: 'live/list',
	  method: 'post',
		data: qs.stringify(data),
	})
}