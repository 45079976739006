<template>
	<div>
		<!-- 头部 -->
		<nav-bar></nav-bar>
		<!-- 工具栏 -->
		<tool-box></tool-box>
		<div class='live-comment-background'>
			<div class="live-class" v-show="liveGradeInfoList.length > 0">
				<a href="#live-class" style="color: red; font-weight: bold;">正在直播班级列表:</a>
				<a :href="'#' + item.sub_title" v-for="(item,index) in liveGradeInfoList" :key="index" @click="getLiveBySpec(item.id,1)" v-text="item.sub_title"></a>
			</div>
			<div class="container">
				<div class="live-title">
					<svg-icon icon-class='banji' class='icon-title'></svg-icon>
					<span class='grade-title' v-text="liveInfo.title ? liveInfo.title:'暂无课程标题'"></span>
				</div>
				<!-- 直播和评论范围 -->
				<div class="live-comment-scope" :style="liveInfo.status != '正在直播' ?  'height: 580px;' : ''">
					<!-- 直播范围 -->
					<div class="live-player-scope">
						<div id="player-con"></div>
					</div>
					<!-- 评论范围 -->
					<div class="comment-scope">
						<div class="live-title-info">
							<h2 class='title' style="color: #FFF;">
								{{liveInfo.title?liveInfo.title:'暂无课程标题'}}
							</h2>
							<div class='slide-item'>
								<span>讲师：</span>
								<span v-if='liveInfo.grade'>{{liveInfo.grade.teachaername}}</span>
								<span v-else>暂无讲师</span>
							</div>
						</div>
						<div class="show-comment scrollbar" >
							<div class="comments-list-item" v-for='(xitem,xindex) in commentsList' :key='xindex'>
								<span>{{xitem.user ? xitem.user : '匿名'}}:</span>{{xitem.data}}
							</div>
						</div>
						<div class="input-comment">
							<input id="comment-input" class="comment-input" type="text" v-model="comment"
								placeholder="输入消息" @keyup.enter="toComment">
							<el-button class="submit-button" type="info"  plain  @click="toComment">发表</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class='industry-test-content container'>
			<a name="live-class"></a><div class="tag-title">直播班级列表</div>
			<hr >
			<div class="tag">
				<div class="judge">
					<div class="tag-item" v-for="item in liveGradeList" :key="item.id" @click="getLiveBySpec(item.id,$event)">
						<a :name="item.sub_title">{{item.sub_title}}</a>
					</div>
				</div>
			</div>
			<div v-if="gradeLiveList.length">
				<div class="subject-live">
					<div class="item-subject" >
						<div class="live-content">
							<el-row :gutter="15">
								<el-col :span='6' style='margin-bottom:15px' v-for='(item,index) in gradeLiveList' :key='index'> 
									<!-- 班型列表模板 -->
									<div class="course-list-item" @click="watchLive(item)">
										<img style="width: 263px; height: 141px;" :src="item.grade.thumb" >
										<div class="title">{{item.title}}</div>
										<div class="info-time">
											<span class="day">开始时间：{{item.live_start_time}}</span><br>
											<span class="day">结束时间：{{item.live_end_time}}</span>
											<span></span>
										</div>
										<div class="enter" v-if="item.status == '正在直播'"><a style="cursor: pointer;font-weight: bold;color: red;" v-text="item.status"> </a></div>
										<div class="enter" v-else><a style="cursor: pointer;font-weight: bold;" v-text="item.status"> </a></div>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</div>
			</div>
			<div class='industry-test-content container' v-else>
				<el-empty description="暂无直播课程"></el-empty>
			</div>
		</div>
		<!-- footer -->
		<web-footer></web-footer>
	</div>
</template>
<script>
	var ws,_this;
	import NavBar from '@/components/page/NavBar'
	import ToolBox from '@/components/toolbox'
	import WebFooter from '@/components/page/WebFooter'
	import sysConfig from '@/utils/config'
	import {
		getLiveList,
		getLiveDetail,
		savePlayRecord,
		getLiveGradeList,
		getGradeLiveList
	} from '@/api/live'
	import {
		getSessionStorage,
		setSessionStorage,
		getLocalStorage
	} from '@/utils/auth'
	import {
		getProSpec
	} from '@/api/index'
	import {
		parseTime
	} from "@/utils/index"
	import {getSiteInfo} from '@/api/system'

	import Hls from 'hls.js';

	export default {
		name: 'CoursePlayer',
		props: ['comboid', 'gradeid', 'sectionid'], //套餐，班级，章节
		data() {
			return {
				comment: '',
				commentsList: [],
				productSpecList: [],
				playerList: {
					title: '图灵科圣网校平台使用说明',
					teacher: [{
						name: '张老师'
					}],
					sections: [{
						title: '图灵科圣网校平台使用说明(一)',
						free: 1
					}],

				}, //播放列表
				liveId:0,
				livePersons:0,
				comboData: {}, //套餐班级列表
				activeGrade: 0, //播放选中
				activeSection: 0, //班级章节选中,
				playerVid: '',
				playerFlag: false, //视频是否允许点击
				playerLoading: true,
				gradeList: [], //班级列表
				specid: '', //分类
				buyFlag: false, //是否购买
				isGrade: false, //是否是班级
				sectionsList: [], //班级章节
				relateList: [], //相关课程
				downloadList: [], //下载章节
				downloadDialogVisible: false,
				courseLoading: true,
				courseList: [],
				videoMask: true,
				initTime: 0,//观看时间累加
				vodPlayerJs: "https://g.alicdn.com/de/prismplayer/2.9.19/aliplayer-min.js",
				//白
				liveGradeList: [],	//专业班级列表
				gradeLiveList: [],	//专业下节列表
				liveInfo: {},				//直播信息
				liveList: [],
				liveGradeInfoList: [],	//正在直播的班级
			}
		},
		components: {
			NavBar,
			ToolBox,
			WebFooter,
		},
		watch: {
			playerList(newval) { //套餐，切换班级
				if (newval.sections && newval.sections.length > 0) {
					let sectionIndex = newval.sections.map(item => item.free).indexOf(1);
					if (sectionIndex == -1) { //都是需要购买
						this.activeSection = 0;
						this.playerVid = newval.sections[this.activeSection].video;
						this.playerFlag = true; //视频不允许点击
						this.playerLoading = false;
					} else {
						this.activeSection = sectionIndex;
						this.playerVid = newval.sections[this.activeSection].video;
						this.playerFlag = false;
						this.playerLoading = false;
					}
				} else {
					this.playerVid = '';
					this.playerLoading = true;
				}
			}
		},
		beforeCreate() {
			_this=this;
		},
		created() {
			this.isGrade = true;
			
		},
		mounted() {
			getSiteInfo().then(res => {
				if(res.code == 1){
					this.siteInfo = res.data
					let img = $("<img style='width: 200px;' alt='暂无图片'>").attr("src",this.siteInfo.pic.logo)
					$(".live-tab-bar>.logo").append(img)
				}
			});
			// 获取直播班级列表
			getLiveGradeList().then(res => {
				if(res.code == 1){
					if(res.data != null && res.data.length > 0){
						this.liveGradeList = res.data;	//直播班级列表
						this.liveGradeInfoList = [];//正在直播的班级列表
						for(let grade of this.liveGradeList){
							this.grade = grade;
							getGradeLiveList({grade_id: grade.id}).then(result => {
								if(result.code == 1 && result.data != null && result.data.length > 0){
									for(let liveInfo of result.data){
										if (liveInfo.status == "正在直播") {
											this.liveGradeInfoList.push(grade)
										}
									}
								}
							})
						}
						this.getLiveBySpec(res.data[0].id);
					}
				}
			})
			//正式站：wss://ws.turingcs.net:8282   
			//测试站：wss://websocket.turingcs.net:8282
			ws = new WebSocket("wss://websocket.turingcs.net:8282"); 
			ws.onmessage = function(e) {
					// json数据转换成js对象
					var data = eval("(" + e.data + ")");
					var type = data.type || '';
					switch (type) {//2. Events.php中返回的init类型的消息，将client_id发给后台进行uid绑定
						case 'init':
							 $.ajax({
								url:sysConfig.baseURL + "/Msg/bind",
								method: 'POST',
								headers: {
									mer: getLocalStorage('mer'),
									token: getLocalStorage('token')?getLocalStorage('token'):''
								},
								data: {
									client_id: data.client_id,
									live_id:_this.liveId,
								},
								success:function(r){
									if(r.code==1){
										_this.livePersons = r.data.persons
									}
								}
							 });
							break;
						default:
							_this.commentsList.push(data);
							_this.$nextTick(()=>{
									 _this.$refs.chatmain.scrollTop= _this.$refs.chatmain.scrollHeight
							})
					}
			}
		},
		methods: {
			loadPlayer(url) {//初始化保利威播放器
				this.player = new Aliplayer({
				  "id": "player-con",
				  "source": this.liveInfo.live,
				  "width": "100%",
				  "height": (850 / 1.78) + "px",
				  "autoplay": true,
				  "isLive": true,
				  "rePlay": false,
				  "playsinline": true,
				  "preload": true,
				  "controlBarVisibility": "hover",
					"showBuffer": true,
				  "useH5Prism": true,
				}, function (player) {
				    console.log("The player is created");
				});
			},
			loadPlayerScript(callback) {//初始化保利威播放器
				const myScript = document.createElement("script");
				myScript.setAttribute("src", this.vodPlayerJs);
				document.body.appendChild(myScript);
				myScript.onload = callback;
			},
			//观看直播
			watchLive(liveInfo){
				if(liveInfo.status == "正在直播"){//切换直播
					this.liveInfo = liveInfo;
					this.player.loadByUrl(liveInfo.live)
				}else{
					alert("当前课程还未开播，开播时间为：" + liveInfo.live_start_time)
				}
			},
			//获取专业下节列表
			getLiveBySpec(gradeId,event){
				if(event == 1){
					$(".tag-item").css("background-color","")
				}else if(event != 1 && event){
					$(".tag-item").css("background-color","")
					if(event.target.localName == "a"){
						$($(event.target).parent()).css("background-color","#a0cfff")
					}else {
						$(event.target).css("background-color","#a0cfff")
					}
				}else {
					this.$nextTick(()=>{
						$($(".tag-item").get(0)).css("background-color","#a0cfff")
					})
				}
				getGradeLiveList({grade_id: gradeId}).then(res => {
					if(res.code == 1){
						let gradeLiveList = res.data;	//专业下节列表
						this.gradeLiveList = gradeLiveList.map(obj =>{
							obj.live_end_time = this.formatDate(new Date(obj.live_end_time * 1000));
							obj.live_start_time = this.formatDate(new Date(obj.live_start_time * 1000));
							return obj;
						})
						
						if(this.gradeLiveList && this.gradeLiveList.length > 0){
							for(let item of gradeLiveList){
								if(item.status == "正在直播"){
									this.liveInfo = item;
									this.loadPlayerScript(this.loadPlayer);
									break;
								}
							}
						}else {
							this.liveInfo = {
								title: "暂无课程正在直播课程",
								status: "未直播"
							}
						}
					}
				})
			},
			formatDate(date){//日期格式化
				let dateFormat = date.getFullYear() + "-" + this.concatZero((date.getMonth() + 1)) + "-" + this.concatZero(date.getDate()) + " " + this.concatZero(date.getHours()) + ":" + this.concatZero(date.getMinutes())  + ":" + this.concatZero(date.getSeconds());
				return dateFormat;
			},
			concatZero(num){
				return num > 9 ? num:"0" + num;
			},
			//保存播放记录
			savePlayRecord(time){
				savePlayRecord({
					time:time
				}).then(res => {
					if(res.code == 1){
					}else{
						this.savePlayRecord(time)
					}
				});
				clearInterval(this.timer);
			},
			//记录每个直播观众的播放时间
			audiencePlayerTime(){
				this.timer = setInterval(()=>{
					this.initTime++;
				},1000)
			},
			toComment() {
				if (getLocalStorage('token')) {
					if(this.comment && this.comment != "" && this.comment.trim() != ""){
						$.ajax({
							url: sysConfig.baseURL + '/Msg/receive',
							// url: 'http://ceshi.turingcs.net/api/Msg/receive',
							method: 'POST',
							headers: {
								mer: getLocalStorage('mer'),
								token: getLocalStorage('token')?getLocalStorage('token'):''
							},
							data: {
								'msg': this.comment
							},
							success: function(r) {
								_this.comment=''
							}
						})
					}
				} else {
					this.comment = "";
					// alert("请先登录");
					this.$notify.error({
					  title: '未登录',
					  message: '请先登录'
					});
					return;
				}
			},
		}
	}
</script>

<style scoped lang="scss">
	// ---------------------------------- 直播选项栏 ------------------------------------
	.live-tab-bar-scope {
		height: 60px;
		background-color: #FFF;
		.live-tab-bar {
			margin: 0 auto;
			width: 1200px;
			height: 60px;
			display: flex;
			align-items: center;
			.logo {
				margin-right: 30px;
			}
			.item {
				font-size: 20px;
				font-weight: bold;
				margin-right: 30px;
			}
		}
	}
	// ---------------------------------- 直播选项栏 ------------------------------------
	
	// ---------------------------------- 直播画面 --------------------------------------
	.live-comment-background{
		padding-bottom: 30px;
		background-color: #23303e;
		.live-class {
			a {
				font-size: 18px;
				color: #FFF;
				margin-right: 30px;
			}
			color: #FFF;
			margin: 0 auto;
			width: 1200px;
			height: 50px;
			border-bottom: 1px solid #eeee;
			display: flex;
			align-items: center;
		}
		.container {
			width: 1200px;
			// height: 528px;
			display: flex;
			flex-direction: column;
			.live-title {
				height: 50px;
				padding: 10px 0px;
				font-size: 18px;
				color: #fff;
				display: flex;
				flex-direction: row;
				align-items: center;
				.icon-title {
					margin-right: 5px;
				}
			}
			.live-comment-scope {
				margin: 0 auto;
				width: 100%;
				// height: 528px;
				display: flex;
				border: 1px solid #334559;
				.live-player-scope {
					flex: 17;
				}
				.comment-scope {
					flex: 7;
					border-left: 1px solid #334559;
					display: flex;
					flex-direction: column;
					.live-title-info {
						flex: 3; 
						padding: 5px 10px;
						color: #FFF;
						.title {
							font-size: 16px;
							height: 30px;
							line-height: 30px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
					.show-comment {
						flex: 7;
						height: 380px;
						border-radius: 10px 10px 0 0;
						background-color: #eeeeee;
						overflow-y: scroll;
						.comments-list-item{
							color:#323232;
							span {
								color: #999;
								margin-right: 10px;
							}
						}
					}
					.input-comment {
						height: 40px;
						background-color: white;
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 3px 6px;
						flex-direction: row;
						.comment-input {
							height: 36px;
							width: 250px;
							border-radius: 10px;
							padding: 5px;
							box-sizing: border-box;
							background-color: #f0f0f020;
						}
						.el-button {
							border-radius: 40px;
						}
						.comment-btn {
							width: 80px;
						}
					}
					
					.scrollbar::-webkit-scrollbar {
						/*滚动条整体样式*/
						  width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
						  height: 1px;
					}
					.scrollbar::-webkit-scrollbar-thumb {
					  /*滚动条里面小方块*/
					  border-radius: 10px;
					  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
					  background   : #535353;
					}
					.scrollbar::-webkit-scrollbar-track {
					  /*滚动条里面轨道*/
					  box-shadow   : inset 0 0 5px rgba(199, 194, 180, 1.0);
					  border-radius: 10px;
					  background   : #ededed;
					}
				}
			}
		}
	}
	
	// ----------------------------------- 直播画面 -------------------------------------
	
	.prism-ErrorMessage {
		display: none;
	}
	#player-con {
		background-color: #000000;
	}
	.player-con {
		background: #23303e;//23303e
		padding: 20px 0px;

		.player {
			.player-title {
				padding: 10px 0px;
				font-size: 18px;
				color: #fff;
				display: flex;
				flex-direction: row;
				align-items: center;

				.icon-title {
					margin-right: 5px;
				}
			}

			.player-list {
				width: 100%;
				height: 530px;
				display: flex;
				flex-direction: row;
				box-sizing: border-box;
				border: 1px solid #334559;
				overflow: hidden;

				.video {
					flex: 1;
					height: 100%;

					.video-mask {
						height: 100%;
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
					}

					.video-player {
						overflow: hidden;
						border-right: 1px solid #334559;
						position: relative;
						.not-live {
							width: 848px;
							height: 528px;
							background-color: #002B36;
							z-index: 10000;
							float: left;
						}
						.video-warning {
							color: #FFFFFF;
							text-align: center;
							font-size: 20px;
						}
						.video-mask {
							width: 100%;
							height: 100%;
							position: absolute;
							left: 0;
							top: 0;
							background-position: center;
							background-size: 100% 100%;
							background-repeat: no-repeat;
							display: flex;
							flex-direction: row;
							justify-content: center;
							align-items: center;
						}
						
					}
				}

				.video-slide {
					width: 348px;
					background-color: #23303e;
					display: flex;
					flex-direction: column;
					border-left: 1px solid #334559;
					color: #fff;

					.slide-title {
						padding: 5px 10px;
						height: 95px;
						color: #fff;

						.title {
							color: #fff;
							font-size: 16px;
							height: 30px;
							line-height: 30px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}

						.slide-item {
							width: 100%;
							display: flex;
							flex-direction: row;
							align-items: center;
							margin: 10px 0px;
							flex: 1;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;

							.teacher-list {
								padding: 0px 5px;
								border-right: 1px solid #fff;
							}

							.teacher-list:last-child {
								border: none;
							}
						}
					}
					
					.scrollbar::-webkit-scrollbar {
						/*滚动条整体样式*/
						  width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
						  height: 1px;
					}
					.scrollbar::-webkit-scrollbar-thumb {
					  /*滚动条里面小方块*/
					  border-radius: 10px;
					  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
					  background   : #535353;
					}
					.scrollbar::-webkit-scrollbar-track {
					  /*滚动条里面轨道*/
					  box-shadow   : inset 0 0 5px rgba(199, 194, 180, 1.0);
					  border-radius: 10px;
					  background   : #ededed;
					}

					.comments {
						height: 190px;
						position: relative;
						.comments-content {
							height: 380px;
							border-radius: 10px 10px 0 0;
							background-color: #eeeeee;
							
							overflow-y: scroll;
							.comments-persons{
								position: absolute;
								right: 0;
								top: 0;
								display: flex;
								justify-content: flex-end;
								padding: 5px;
								.persons{
									background-color: rgba($color: black, $alpha: 0);
									height: 30px;
									display: flex;
									align-items: center;
									padding: 5px 10px;
									color: #000066;
								}
							}
							.comments-list{
								padding: 10px;
								.comments-list-item{
									color:#323232;
									span {
										color: #999;
										margin-right: 10px;
									}
								}
							}
						}
					
						.comments-foot {
							height: 40px;
							background-color: white;
							display: flex;
							align-items: center;
							justify-content: space-between;
							padding: 3px 6px;
							flex-direction: row;
					
							.comment-input {
								height: 36px;
								width: 250px;
								border-radius: 10px;
								padding: 5px;
								box-sizing: border-box;
								background-color: #f0f0f020;
							}
							.el-button {
								border-radius: 40px;
							}
							.comment-btn {
								width: 80px;
							}
						}
					}

					.slide-tools {
						ul {
							display: flex;
							flex-direction: row;
							justify-content: center;
							align-items: center;

							li {
								height: 55px;
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								flex: 1;
								font-size: 12px;
								cursor: pointer;

								.icon-tools {
									margin-bottom: 3px;
									font-size: 18px;
								}
							}

							li:hover {
								color: $theme-color;
							}
						}
					}
				}
			}
		}
	}

	.combo-buy {
		margin: 15px auto;

		.buy-btn {
			padding: 20px 20px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;

			.price {
				color: $price-color;
				margin-right: 50px;

				span {
					font-size: 20px;
				}
			}
		}
	}

	//班级列表
	.grade-content {
		margin-top: 15px;

		.card-header {
			padding: 0px 20px;
			height: 55px;
			display: flex;
			flex-direction: row;
			align-items: center;

			span {
				font-size: 20px;
				font-weight: bold;
				padding-left: 10px;
				border-left: 3px solid $theme-color;
			}
		}

		.grade-list {
			padding: 10px 20px;

			.combo-list {
				.list-title {
					font-size: 18px;
					font-weight: bold;
					padding: 10px 0px;
				}

				.list-content {
					li {
						padding: 10px 10px;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 5px;

						.listen {
							font-size: 12px;
							color: $theme-color;

							.icon-listen {
								font-size: 14px;
							}
						}

					}

					li:hover {
						color: $theme-color;
						background-color: #eee;
					}

					li.gradesActive {
						color: $theme-color;
						background-color: #eee;
					}
				}
			}
		}
	}

	.combo-relate {
		.relate-title {
			height: 55px;
			display: flex;
			flex-direction: row;
			align-items: center;

			span {
				font-size: 20px;
				font-weight: bold;
				padding-left: 10px;
				border-left: 3px solid $theme-color;
			}
		}

		.relate-content {
			.relate-item {
				margin-bottom: 15px;
			}
		}
	}

	.downloadListItem {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		line-height: 30px;
		border-bottom: solid 1px #eee;
	}

	.subject-live {
		position: relative;
		min-height: 500px;
		margin-top: 30px;
		padding-bottom: 30px;
	}

	.subject-live .item-subject {
		margin-top: 30px
	}

	.subject-live .item-subject .subject-title {
		font-size: 24px;
		padding-bottom: 10px;
		border-bottom: 1px solid #eee;
		overflow: hidden
	}

	.subject-live .item-subject .subject-title span {
		float: left;
		border-left: 6px solid #fb4343;
		padding-left: 30px;
		font-weight: 700
	}

	.subject-live .item-subject .subject-title .more {
		float: right;
		font-size: 14px;
		color: #666
	}

	.course-list-item {
		display: flex;
		flex-direction: column;
		.info-time {
			.day {
				font-size: 10px;
				font-weight: bold;
				color: #6a6765;
			}
		}
		.enter {
			font-size: 20px;
			text-align: center;
			margin-top: 5px;
			a {
				color: #0198BD;
				font-weight: bold;
			}
			a:hover {
				color: #ff4b4b;
			}
		}
		height: 256px;
		background-color: #fff;
		box-shadow: 0 3px 5px 0 rgba(153, 153, 171, .3);
		box-sizing: border-box;
		padding: 1px 10px 0 15px;
		transition: all .5s;
		-webkit-transition: all .5s;
		-moz-transition: all .5s;
		-o-transition: all .5s
	}

	.course-list-item:hover {
		transform: translateY(-5px);
		-ms-transform: translateY(-5px);
		-moz-transform: translateY(-5px);
		-webkit-transform: translateY(-5px);
		-o-transform: translateY(-5px);
		box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .2)
	}

	.course-list-item .title {
		font-weight: bold;
		font-size: 16px;
		max-height: 48px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		margin-top: 15px;
		color: #2c2e32
	}

	.live-list>div:first-child {
		float: right;
		width: 120px;
		color: #ff3f6b;
		font-size: 14px;
		margin-top: 20px;
		text-align: right
	}

	.live-list>div:first-child span {
		color: #2c2e32
	}

	.live-list>div:nth-child(2) {
		width: 66px;
		height: 100px;
		float: left;
		margin-top: 33px
	}

	.live-list>div:nth-child(2) img {
		width: 100%;
		height: 100%;
		border-radius: 10px
	}

	.live-list>div:nth-child(3) {
		margin: 40px 0 0 7px;
		float: left;
		color: #2d2f33;
		width: 100px;
		height: 16px;
		text-overflow: ellipsis;
		overflow: hidden
	}

	.live-list>div:nth-child(4) {
		float: left;
		margin: 65px 0 0 -107px;
		width: 79px;
		height: 18px;
		background-color: #fafafa;
		border-radius: 9px;
		color: #90929a;
		font-size: 12px;
		line-height: 18px;
		text-align: center
	}

	.live-list>div:nth-child(5) {
		float: left;
		color: #3f56ff;
		margin-top: 13px;
		width: 269px;
		height: 16px;
		text-overflow: ellipsis;
		overflow: hidden
	}

	.live-list>div:nth-child(6) {
		float: left;
		width: 120px;
		height: 32px;
		background-color: #fff2f5;
		border-radius: 16px;
		line-height: 32px;
		text-align: center;
		margin: 13px 0 0 64.5px
	}

	.live-list>div:nth-child(6) a {
		color: #fb4346
	}

	.live-list>div:nth-child(7) {
		float: right;
		width: 120px;
		height: 32px;
		background-color: #fff;
		border-radius: 16px;
		line-height: 32px;
		text-align: center;
		margin: 13px 0 0 10px;
		border: 1px solid #fff2f5;
		color: #fb4346;
		cursor: pointer
	}

	.day {
		margin-right: 10px
	}
	
	#xgVideo {
		width: 500px;
		height: 500px;
	}
	
	.tag-title {
		margin-top: 30px;
		font-size: 30px;
		font-weight: bold;
		color: #005b6f;
	}
	
	.tag {
		margin-top: 10px;
		.judge {
			display: flex;
			flex-wrap: wrap;
			.tag-item {
				margin-right: 20px;
				margin-bottom: 10px;
				padding: 10px;
				border-radius: 10px;
				height: 20px;
				background-color: #FFFFFF;
				border: 0.5px solid #0198BD;
				color: #0198BD;
				font-weight: bold;
				cursor: pointer;
				a {
					color: #0198BD;
				}
			}
			.tag-item:first-child {
				margin-left: 0;
			}
		}
	}
</style>
<style lang="postcss" type="text/css">
	@import 'https://g.alicdn.com/de/prismplayer/2.7.2/skins/default/aliplayer-min.css';
</style>